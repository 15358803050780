import { useState, FC } from 'react';

import { useApplication } from '@common/application';
import { LangaugeSelectorDropdownProps } from '@sitecore/types/manual/LanguageSelectorDropdown';
import { Stack, Text, VisuallyHidden } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { useI18nTranslation } from '@sparky/providers';
import { textColors } from '@sparky/themes/eneco/tokens';

import {
  DropdownItem,
  DropdownContainer,
  DropdownButton,
  DropdownMenuContainer,
  DropdownMenu,
  DropdownLink,
} from './LanguageSelectorDropdown.css';

export const LangaugeSelectorDropdown: FC<LangaugeSelectorDropdownProps> = ({ languages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const languageSelectorDropdownA11yButton = useI18nTranslation('languageSelectorDropdownA11yButton');
  const { searchParams } = useApplication();

  const isDesktop = useMediaQuery('lg');

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className={DropdownContainer}>
      <button
        id="language-selector-button"
        className={DropdownButton}
        aria-expanded={isOpen}
        aria-controls="language-selector-menu"
        aria-haspopup="true"
        onClick={toggleDropdown}>
        <Stack as="span" direction="row">
          {languages.find(language => language.active) && (
            <Text weight="bold" color={isDesktop ? 'textLowEmphasis' : undefined} size="BodyS" aria-hidden="true">
              {languages.find(language => language.active)?.languageDisplayName}
            </Text>
          )}
          <VisuallyHidden>{languageSelectorDropdownA11yButton}</VisuallyHidden>
          <DropDownCaret aria-hidden="true" />
        </Stack>
      </button>
      <div id="language-selector-menu">
        {isOpen && (
          <div role="menu" aria-labelledby="language-selector-button" className={DropdownMenuContainer}>
            <ul className={DropdownMenu}>
              {languages.map(language => (
                <li key={language.language} role="menuitem" className={DropdownItem}>
                  <a href={`${language.url}?${searchParams}`} className={DropdownLink}>
                    {language.languageDisplayName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const DropDownCaret: FC = () => {
  const isDesktop = useMediaQuery('lg');

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4676 6.92825L9.99553 10.4003L6.52349 6.92825C6.1745 6.57926 5.61074 6.57926 5.26174 6.92825C4.91275 7.27724 4.91275 7.841 5.26174 8.18999L9.36913 12.2974C9.71812 12.6464 10.2819 12.6464 10.6309 12.2974L14.7383 8.18999C15.0872 7.841 15.0872 7.27724 14.7383 6.92825C14.3893 6.5882 13.8166 6.57926 13.4676 6.92825Z"
        fill={isDesktop ? textColors.textLowEmphasis : textColors.textPrimary}
      />
    </svg>
  );
};
